import React, { useEffect } from 'react';
import { BsX } from 'react-icons/bs';
import './Popup.css';

const ErrorPopup = ({ message, onClose }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <div className="popup-container">
            <div className="error-popup">
                <span className='close-alert-span' onClick={onClose}>
                    <BsX />
                </span>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default ErrorPopup;
