import React, { useState, useEffect, useCallback, Fragment  } from 'react';
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import { Table } from 'react-bootstrap';
import { AdventureActivitiesListElement } from './AdventureActivitiesListElement';
import ErrorPopup from '../../Alerts/ErrorPopup';
import '../Settings.css';

export function AdventureActivitiesList() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [adventureActivities, setAdventureActivities] = useState([]);

    const handleCreate = () => {
        navigate('/create_adventure_activity');
    }

    const fetchAdventureActivities = useCallback(async () => {
        api.get('/api/forms/adventure_activities_list/')
        .then((response) => {
            setAdventureActivities(response.data);
        })
        .catch((error) => {
            setError('Ezin izan dira abenturazko ekintzak eskuratu.')
        });
    }, []);

    useEffect(() => {
        fetchAdventureActivities();
    }, [fetchAdventureActivities]);

    const handleDeleteAdventureActivity = (adventureActivityId) => {
        api.delete('/api/forms/adventure_activity/', { data: { adventure_activity_id: adventureActivityId }})
        .then((response) => {
            setAdventureActivities(adventureActivities.filter(adventureActivity => adventureActivity.id !== adventureActivityId));
        })
        .catch((error) => {
            setError('Ezin izan da abenturazko ekintza ezabatu.');
        });
    };

    const handleBack = () => {
        navigate("/settings");
    }

    return (
        <Fragment>
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className='main-content'>
                <div className='table-head-options'>
                    <button className="back-button" onClick={handleBack}>Atzera</button>
                    <button className='create-button' onClick={handleCreate}>Sortu</button>
                </div>
                <div className='table-main'>
                    <div className="table-elements">
                        <Table>
                            <thead className="forms-header">
                                <tr>
                                    <th>Titulua EU</th>
                                    <th>Titulua ES</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={5} style={{ width: '100%', paddingBottom: '5px' }}>
                                        <hr />
                                    </td>
                                </tr>
                                {adventureActivities.length === 0 ? (
                                    <tr>
                                        <td className='no-forms' colSpan={6}>
                                            <div>Ez dago abenturazko ekintzarik</div>
                                        </td>
                                    </tr>
                                ) : (
                                    adventureActivities.map((adventureActivity) => (
                                        <AdventureActivitiesListElement 
                                            key={adventureActivity.id} 
                                            adventureActivity={adventureActivity}
                                            onDeleteAdventureActivity={handleDeleteAdventureActivity} 
                                        />
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
