import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsX } from 'react-icons/bs';
import api from "../../../api/api";
import SuccessPopup from "../../Alerts/SuccessPopup";
import ErrorPopup from "../../Alerts/ErrorPopup";
import "../../../App.css";

export function SetDefaultInsuranceFiles() {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [defaultInsuranceFiles, setDefaultInsuranceFiles] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState('eu');

    useEffect(() => {
        const fetchDefaultInsuranceFiles = async () => {
            api.get('/api/forms/default_insurance_files/')
            .then((response) => {
                setDefaultInsuranceFiles(response.data);
            })
            .catch((error) => {
                setError('Ezin izan dira fitxategiak eskuratu.');
            });
        };

        fetchDefaultInsuranceFiles();
    }, []);

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleChange = (e, destination) => {
        const file = e.target.files[0];
        setDefaultInsuranceFiles(prevState => {
            const updatedFiles = { ...prevState };
            if (!updatedFiles[selectedLanguage]) {
                updatedFiles[selectedLanguage] = {};
            }
            updatedFiles[selectedLanguage][destination].file = file;
            return updatedFiles;
        });
    };
    
    const handleBack = () => {
        navigate("/settings");
    }

    const handleDelete = (destination) => {
        setDefaultInsuranceFiles(prevState => {
            const updatedFiles = { ...prevState };
            if (updatedFiles[selectedLanguage] && updatedFiles[selectedLanguage][destination]) {
                updatedFiles[selectedLanguage][destination].file = null;
            }
            return updatedFiles;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        // Iterar sobre cada idioma y sus archivos
        for (const [lang, files] of Object.entries(defaultInsuranceFiles)) {
            for (const [destination, fileData] of Object.entries(files)) {
                if (fileData.file) {
                    if (fileData.file instanceof File) {
                        formData.append(`${lang}.${destination}`, fileData.file);
                    } else {
                        formData.append(`${lang}.${destination}`, true);
                    }
                }
            }
        }

        // Call to the API.
        api.post("/api/forms/default_insurance_files/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then((response) => {
            setSuccess('Aldaketak zuzen gorde dira.');
        })
        .catch((error) => {
            setError('Ezin izan dira fitxategiak eguneratu.');
        });
    };

    return (
        <Fragment>
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className="main-content">
                <button className="back-button" onClick={handleBack}>Atzera</button>
                <div className="form-content">
                    <div className="form-container">
                        <form className="create-form" onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="field-name">Hizkuntza:</label>
                                <select 
                                    value={selectedLanguage} 
                                    onChange={handleLanguageChange}
                                    className="language-selector"
                                >
                                    {Object.keys(defaultInsuranceFiles).map((lang) => (
                                        <option key={lang} value={lang}>
                                            {lang.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-content">
                                {defaultInsuranceFiles[selectedLanguage] ? (
                                    Object.entries(defaultInsuranceFiles[selectedLanguage]).map(([destination, data], index) => (
                                        <div className="field" key={index}>
                                            <label className="field-name">{data.name}:</label>
                                            <div className="file-input">
                                                <label htmlFor={`insurance-file-${index}`}>Aukeratu fitxategia</label>
                                                <input
                                                    type="file"
                                                    id={`insurance-file-${index}`}
                                                    name={`insuranceFile-${index}`}
                                                    onChange={(e) => handleChange(e, destination)}
                                                />
                                            </div>
                                            <span className="file-input-span">
                                                {!data.file ? (
                                                    <p>Ez da aukeratu fitxategirik.</p>
                                                ) : (
                                                    <div className="file-info">
                                                        {data.file.url && (<p className="file-name"><a href={data.file.url} target="_blank" rel="noreferrer">{data.file.name}</a></p>)}
                                                        {!data.file.url && (<p className="file-name"><a href={URL.createObjectURL(data.file)} target="_blank" rel="noreferrer">{data.file.name}</a></p>)}
                                                        <span 
                                                            className='delete-file'
                                                            onClick={() => handleDelete(destination)}
                                                        >
                                                            <BsX />
                                                        </span>
                                                    </div>
                                                )}
                                            </span>
                                        </div>
                                    ))
                                ) : (
                                    <p>Ez da informaziorik aurkitu hizkuntza aukoratzen.</p>
                                )}
                            </div>
                            <button className="create-button" type="submit">Gorde</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}