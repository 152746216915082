import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsX } from 'react-icons/bs';
import api from "../../../api/api";
import SuccessPopup from "../../Alerts/SuccessPopup";
import ErrorPopup from "../../Alerts/ErrorPopup";
import "../../../App.css";

export function SetDefaultFiles() {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(null);
    const [defaultFiles, setDefaultFiles] = useState({});
    const [error, setError] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('eu');

    useEffect(() => {
        const fetchDefaultFiles = async () => {
            try {
                const response = await api.get('/api/forms/default_files/');
                setDefaultFiles(response.data);
            } catch (error) {
                setError('Ezin izan dira fitxategiak eskuratu.');
            }
        };

        fetchDefaultFiles();
    }, []);

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleChange = (e, file_type) => {
        const file = e.target.files[0];
        setDefaultFiles(prevState => {
            const updatedFiles = { ...prevState };
            if (!updatedFiles[selectedLanguage]) {
                updatedFiles[selectedLanguage] = {};
            }
            updatedFiles[selectedLanguage][file_type].file = file;
            return updatedFiles;
        });
    };

    const handleDelete = (file_type) => {
        setDefaultFiles(prevState => {
            const updatedFiles = { ...prevState };
            if (updatedFiles[selectedLanguage] && updatedFiles[selectedLanguage][file_type]) {
                updatedFiles[selectedLanguage][file_type].file = null;
            }
            return updatedFiles;
        });
    };

    const handleBack = () => {
        navigate("/settings");
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        // Iterar sobre cada idioma y sus archivos
        for (const [lang, files] of Object.entries(defaultFiles)) {
            for (const [fileType, fileData] of Object.entries(files)) {
                if (fileData.file) {
                    if (fileData.file instanceof File) {
                        formData.append(`${lang}.${fileType}`, fileData.file);
                    } else {
                        formData.append(`${lang}.${fileType}`, true);
                    }
                }
            }
        }

        try {
            await api.post("/api/forms/default_files/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            setSuccess('Aldaketak zuzen gorde dira.');
        } catch (error) {
            setError('Ezin izan dira eguneratu.');
        }
    };

    return (
        <Fragment>
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className="main-content">
                <button className="back-button" onClick={handleBack}>Atzera</button>
                <div className="form-content">
                    <div className="form-container">
                        <form className="create-form" onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="field-name">Hizkuntza:</label>
                                <select 
                                    value={selectedLanguage} 
                                    onChange={handleLanguageChange}
                                    className="language-selector"
                                >
                                    {Object.keys(defaultFiles).map((lang) => (
                                        <option key={lang} value={lang}>
                                            {lang.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-content">
                                {defaultFiles[selectedLanguage] ? (
                                    Object.entries(defaultFiles[selectedLanguage]).map(([file_type, file_data], index) => (
                                        <div className="field" key={index}>
                                            <label className="field-name">{file_data.name}:</label>
                                            <div className="file-input">
                                                <label htmlFor={`default-file-${selectedLanguage}-${index}`}>Aukeratu fitxategia</label>
                                                <input
                                                    type="file"
                                                    id={`default-file-${selectedLanguage}-${index}`}
                                                    name={`defaultFile-${selectedLanguage}-${index}`}
                                                    onChange={(e) => handleChange(e, file_type)}
                                                />
                                            </div>
                                            <span className="file-input-span">
                                                {!file_data.file ? (
                                                    <p>Ez da aukeratu fitxategirik.</p>
                                                ) : (
                                                    <div className="file-info">
                                                        {file_data.file.url ? (
                                                            <p className="file-name">
                                                                <a href={file_data.file.url} target="_blank" rel="noreferrer">
                                                                    {file_data.file.name}
                                                                </a>
                                                            </p>
                                                        ) : (
                                                            <p className="file-name">
                                                                <a href={URL.createObjectURL(file_data.file)} target="_blank" rel="noreferrer">
                                                                    {file_data.file.name}
                                                                </a>
                                                            </p>
                                                        )}
                                                        <span 
                                                            className='delete-file'
                                                            onClick={() => handleDelete(file_type)}
                                                        >
                                                            <BsX />
                                                        </span>
                                                    </div>
                                                )}
                                            </span>
                                        </div>
                                    ))
                                ) : (
                                    <p>Ez da informaziorik aurkitu hizkuntza aukoratzen.</p>
                                )}
                            </div>
                            <button className="create-button" type="submit">Gorde</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
