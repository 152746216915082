import React, { useState, useEffect, useCallback, Fragment  } from 'react';
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import { Table } from 'react-bootstrap';
import { UsersListElement } from './UsersListElement';
import ErrorPopup from '../../Alerts/ErrorPopup';
import '../Settings.css';

export function UsersList() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);

    const handleCreate = () => {
        navigate('/create_user');
    }

    const fetchUsers = useCallback(async () => {
        api.get('/api/users/users_list/')
        .then((response) => {
            setUsers(response.data);
        })
        .catch((error) => {
            setError('Ezin izan dira erabiltzaileak eskuratu.')
        });
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleDeleteUser = (userId) => {
        api.delete('/api/users/delete/', { data: { user_id: userId }})
        .then((response) => {
            setUsers(users.filter(user => user.id !== userId));
        })
        .catch((error) => {
            setError('Ezin izan da erabiltzailea ezabatu.')
        });
    };

    const handleBack = () => {
        navigate("/settings");
    }

    return (
        <Fragment>
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className='main-content'>
                <div className='table-head-options'>
                    <button className="back-button" onClick={handleBack}>Atzera</button>
                    <button className='create-button' onClick={handleCreate}>Sortu</button>
                </div>
                <div className='table-main'>
                    <div className="table-elements">
                        <Table>
                            <thead className="forms-header">
                                <tr>
                                    <th>Erabiltzaile izena</th>
                                    <th>Izena</th>
                                    <th>Abizena(k)</th>
                                    <th>Emaila</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={5} style={{ width: '100%', paddingBottom: '5px' }}>
                                        <hr />
                                    </td>
                                </tr>
                                {users.length === 0 ? (
                                    <tr>
                                        <td className='no-forms' colSpan={6}>
                                            <div>Ez dago erabiltzailerik</div>
                                        </td>
                                    </tr>
                                ) : (
                                    users.map((user) => (
                                        <UsersListElement 
                                            key={user.id} 
                                            user={user}
                                            onDeleteUser={handleDeleteUser} 
                                        />
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
