import React, { Fragment } from 'react';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import './MainLayout.css';


export const MainLayout = ( { children } ) => {

    return (
        <Fragment>
            <div className="main-container">
                <Header />
                <div className="content">
                    <Sidebar />
                    { children }
                </div>
          </div>
        </Fragment>
      
    );
}