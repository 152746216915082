import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import ProtectedRoute from './routes/ProtectedRoute';
import AdminRoute from './routes/AdminRoute';
import { Login } from "./components/Login/Login";
import { ForgotPassword } from "./components/Login/ForgotPassword";
import { ResetPassword } from "./components/Login/ResetPassword";
import { MainLayout } from "./components/MainLayout/MainLayout";
import { Forms } from "./components/Forms/Forms";
import { CreateForm } from './components/Forms/CreateForm';
import { EditForm } from './components/Forms/EditForm';
import { Settings } from './components/Settings/Settings';
import { CreateUser } from './components/Settings/Users/CreateUser';
import { UsersList } from './components/Settings/Users/UsersList';
import { EditMyUser } from './components/Settings/Users/EditMyUser';
import { EditUser } from './components/Settings/Users/EditUser';
import { EditSummerExperiences } from './components/Settings/Forms/EditSummerExperiences';
import { SetDefaultRulesFiles } from './components/Settings/Forms/SetDefaultRulesFiles';
import { AdventureActivitiesList } from './components/Settings/Forms/AdventureActivitiesList';
import { CreateAdventureActivity } from './components/Settings/Forms/CreateAdventureActivity';
import { EditAdventureActivity } from './components/Settings/Forms/EditAdventureActivity';
import { SetDefaultFiles } from './components/Settings/Forms/SetDefaultOtherFiles';
import { SetDefaultInsuranceFiles } from './components/Settings/Forms/SetDefaultInsuranceFiles';
import { FormInstances } from './components/Forms/FormInstances';
import { FormInstancesPreview } from './components/Forms/FormInstancesPreview';

function App() {
    return (
        <UserProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot_password" element={<ForgotPassword />} />
                    <Route path="/reset_password/:uidb64/:token" element={<ResetPassword />} />
                    <Route path="/home" element={<ProtectedRoute><MainLayout><Forms /></MainLayout></ProtectedRoute>} />
                    <Route path="/create_form" element={<ProtectedRoute><MainLayout><CreateForm /></MainLayout></ProtectedRoute>} />
                    <Route path="/edit_form/:formId" element={<ProtectedRoute><MainLayout><EditForm /></MainLayout></ProtectedRoute>} />
                    <Route path="/form_instances/:formId" element={<ProtectedRoute><MainLayout><FormInstances /></MainLayout></ProtectedRoute>} />
                    <Route path="/form_instances_preview/:formId" element={<FormInstancesPreview />} />
                    <Route path="/settings" element={<ProtectedRoute><MainLayout><Settings /></MainLayout></ProtectedRoute>} />
                    <Route path="/create_user" element={<AdminRoute><MainLayout><CreateUser /></MainLayout></AdminRoute>} />
                    <Route path="/users_list" element={<AdminRoute><MainLayout><UsersList /></MainLayout></AdminRoute>} />
                    <Route path="/edit_user" element={<ProtectedRoute><MainLayout><EditMyUser /></MainLayout></ProtectedRoute>} />
                    <Route path="/edit_user/:userId" element={<AdminRoute><MainLayout><EditUser /></MainLayout></AdminRoute>} />
                    <Route path="/news" element={<AdminRoute><MainLayout><EditSummerExperiences /></MainLayout></AdminRoute>} />
                    <Route path="/default_rules_files" element={<MainLayout><SetDefaultRulesFiles /></MainLayout>} />
                    <Route path="/default_insurance_files" element={<MainLayout><SetDefaultInsuranceFiles /></MainLayout>} />
                    <Route path="/default_files" element={<MainLayout><SetDefaultFiles /></MainLayout>} />
                    <Route path="/adventure_activities_list" element={<AdminRoute><MainLayout><AdventureActivitiesList /></MainLayout></AdminRoute>} />
                    <Route path="/create_adventure_activity" element={<AdminRoute><MainLayout><CreateAdventureActivity /></MainLayout></AdminRoute>} />
                    <Route path="/edit_adventure_activity/:adventureActivityId" element={<AdminRoute><MainLayout><EditAdventureActivity /></MainLayout></AdminRoute>} />


                </Routes>
            </BrowserRouter>
        </UserProvider>
    );
}

export default App;
