import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return <div />;
  }

  if (!loading && !user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
