import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import { BsPlusCircleFill, BsX } from 'react-icons/bs';
import SuccessPopup from "../../Alerts/SuccessPopup";
import ErrorPopup from "../../Alerts/ErrorPopup";

export function EditAdventureActivity() {
    const navigate = useNavigate();
    const { adventureActivityId } = useParams();
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [adventureActivityData, setAdventureActivityData] = useState({
        id: "",
        title_eu: "",
        title_es: "",
        description_eu: "",
        description_es: "",
        file: false,
        fileSelected_eu: null,
        fileSelected_es: null,
        choices_eu: [],
        choices_es: []
    });
    const newActionRefEU = useRef(null);
    const newActionRefES = useRef(null);

    useEffect(() => {
        api.get(`/api/forms/adventure_activity/?adventure_activity_id=${adventureActivityId}`)
        .then(response => {
            const {
                id,
                title_eu,
                title_es,
                description_eu,
                description_es,
                file_eu,
                file_es,
                choices_eu,
                choices_es
            } = response.data;

            // Determinar si 'file' debe ser true o false basado en 'file_eu' y 'file_es'
            const hasFiles = file_eu || file_es;

            setAdventureActivityData({
                id: id,
                title_eu: title_eu,
                title_es: title_es,
                description_eu: description_eu,
                description_es: description_es,
                file: !!hasFiles, // Convierte a booleano
                fileSelected_eu: file_eu || null,
                fileSelected_es: file_es || null,
                choices_eu: choices_eu,
                choices_es: choices_es
            });
        })
        .catch(error => {
            setError("Ezin izan da abenturazko ekintzaren informazioa kargatu.");
        });
    }, [adventureActivityId]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        setAdventureActivityData({
            ...adventureActivityData,
            [name]: type === "checkbox" ? checked : type === "file" ? files[0] : value,
        });
    };

    const handleAddActionEU = (e) => {
        e.preventDefault();
        const newItem = newActionRefEU.current.value.trim();
        if (newItem) {
            setAdventureActivityData((prevData) => ({
                ...prevData,
                choices_eu: [...prevData.choices_eu, newItem]
            }));
            newActionRefEU.current.value = '';
        }
    };

    const handleRemoveActionEU = (index) => {
        setAdventureActivityData((prevData) => {
            const updatedList = [...prevData.choices_eu];
            updatedList.splice(index, 1);
            return {
                ...prevData,
                choices_eu: updatedList
            };
        });
    };

    const handleAddActionES = (e) => {
        e.preventDefault();
        const newItem = newActionRefES.current.value.trim();
        if (newItem) {
            setAdventureActivityData((prevData) => ({
                ...prevData,
                choices_es: [...prevData.choices_es, newItem]
            }));
            newActionRefES.current.value = '';
        }
    };

    const handleRemoveActionES = (index) => {
        setAdventureActivityData((prevData) => {
            const updatedList = [...prevData.choices_es];
            updatedList.splice(index, 1);
            return {
                ...prevData,
                choices_es: updatedList
            };
        });
    };

    const validateForm = () => {
        const fieldNames = {
            title_eu: 'Titulua (EU)',
            title_es: 'Titulua (ES)',
            description_eu: 'Azalpena (EU)',
            description_es: 'Azalpena (ES)'
        };

        const requiredFields = [
            "title_eu",
            "title_es",
            "description_eu",
            "description_es"
        ];

        for (const field of requiredFields) {
            if (!adventureActivityData[field]) {
                const fieldName = fieldNames[field] || field;
                setError(`${fieldName} eremua hutsik dago.`);
                return false;
            }
        }

        if (adventureActivityData.file && (!adventureActivityData.fileSelected_eu || !adventureActivityData.fileSelected_es)) {
            setError('Fitxategiaren aukera aktibatu duzu eta ez duzu fitxategirik aukeratu.');
            return false;
        }

        if (adventureActivityData.choices_eu.length === 0) {
            setError('Gutxienez ekintza bat gehitu behar da.');
            return false;
        }

        // Check if any choices has been added.
        if (adventureActivityData.choices_eu.length !== adventureActivityData.choices_es.length) {
            setError('Aukeren zerrendek elementu kopuru berdina izan behar dute.');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        let dataToSend = {
            adventure_activity_id: adventureActivityId,
            title_eu: adventureActivityData.title_eu,
            title_es: adventureActivityData.title_es,
            description_eu: adventureActivityData.description_eu,
            description_es: adventureActivityData.description_es,
            choices_eu: adventureActivityData.choices_eu,
            choices_es: adventureActivityData.choices_es,
            file: adventureActivityData.file
        };

        if (adventureActivityData.file) {
            dataToSend = {
                ...dataToSend,
                file_selected_eu: adventureActivityData.fileSelected_eu,
                file_selected_es: adventureActivityData.fileSelected_es
            };
        } else {
            dataToSend = {
                ...dataToSend,
                file_selected_eu: null,
                file_selected_es: null
            };
        }

        api.put("/api/forms/adventure_activity/", dataToSend, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then((response) => {
            setSuccess('Aldaketak zuzen gorde dira.');
        })
        .catch((error) => {
            setError('Ezin izan da abenturazko ekintza eguneratu.');
        });
    };

    const handleBack = () => {
        navigate("/adventure_activities_list");
    }

    return (
        <Fragment>
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className="main-content">
                <button className="back-button" onClick={handleBack}>Atzera</button>
                <div className="form-content">
                    <div className="form-container">
                        <form className="create-form" onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="field-name">Titulua (EU):</label>
                                <input type="text" name="title_eu" value={adventureActivityData.title_eu} onChange={handleChange} maxLength={128} />
                            </div>
                            <div className="field">
                                <label className="field-name">Titulua (ES):</label>
                                <input type="text" name="title_es" value={adventureActivityData.title_es} onChange={handleChange} maxLength={128} />
                            </div>
                            <div className="field">
                                <label className="textarea-field-name">Azalpena (EU):</label>
                                <textarea name="description_eu" value={adventureActivityData.description_eu} onChange={handleChange} maxLength={2048} />
                            </div>
                            <div className="field">
                                <label className="textarea-field-name">Azalpena (ES):</label>
                                <textarea name="description_es" value={adventureActivityData.description_es} onChange={handleChange} maxLength={2048} />
                            </div>
                            <div className="field">
                                <label className="field-name">Fitxategia:</label>
                                <label className="switch">
                                    <input type="checkbox" name="file" checked={adventureActivityData.file} onChange={handleChange} />
                                    <span className={'slider round'}></span>
                                </label>
                            </div>
                            {adventureActivityData.file && (
                                <>
                                <div className="field">
                                    <label className="field-name"></label>
                                    <div className="file-input">
                                        <label htmlFor="file-selected-eu">Aukeratu fitxategia (EU)</label>
                                        <input type="file" id="file-selected-eu" name="fileSelected_eu" onChange={handleChange} />
                                    </div>
                                    <span className={'file-input-span'}>
                                        {!adventureActivityData.fileSelected_eu && (<p>Ez da aukeratu fitxategirik.</p>)}
                                        {adventureActivityData.fileSelected_eu && adventureActivityData.fileSelected_eu.url && (<p><a href={adventureActivityData.fileSelected_eu.url} target="_blank" rel="noreferrer">{adventureActivityData.fileSelected_eu.name}</a></p>)}
                                        {adventureActivityData.fileSelected_eu && !adventureActivityData.fileSelected_eu.url && (<p><a href={URL.createObjectURL(adventureActivityData.fileSelected_eu)} target="_blank" rel="noreferrer">{adventureActivityData.fileSelected_eu.name}</a></p>)}
                                    </span>
                                </div>
                                <div className="field">
                                    <label className="field-name"></label>
                                    <div className="file-input">
                                        <label htmlFor="file-selected-es">Aukeratu fitxategia (ES)</label>
                                        <input type="file" id="file-selected-es" name="fileSelected_es" onChange={handleChange} />
                                    </div>
                                    <span className={'file-input-span'}>
                                        {!adventureActivityData.fileSelected_es && (<p>Ez da aukeratu fitxategirik.</p>)}
                                        {adventureActivityData.fileSelected_es && adventureActivityData.fileSelected_es.url && (<p><a href={adventureActivityData.fileSelected_es.url} target="_blank" rel="noreferrer">{adventureActivityData.fileSelected_es.name}</a></p>)}
                                        {adventureActivityData.fileSelected_es && !adventureActivityData.fileSelected_es.url && (<p><a href={URL.createObjectURL(adventureActivityData.fileSelected_es)} target="_blank" rel="noreferrer">{adventureActivityData.fileSelected_es.name}</a></p>)}
                                    </span>
                                </div>
                                </>
                            )}
                            <div className="field">
                                <label className="field-name">Aukeren zerrenda (EU):</label>
                                <div className="input-with-icon">
                                    <input type="text" ref={newActionRefEU} />
                                    <span className={'create-item-span'} onClick={handleAddActionEU}>
                                        <BsPlusCircleFill />
                                    </span>
                                </div>
                            </div>
                            <div className="item-list-container">
                                <ul className="item-list">
                                    {adventureActivityData.choices_eu.map((item, index) => (
                                        <li key={index} className="item-with-icon">
                                            <span className={'action-span-limited'}>{item}</span>
                                            <span className={'delete-item-span'} onClick={() => {
                                                handleRemoveActionEU(index);
                                            }}>
                                                <BsX />
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="field">
                                <label className="field-name">Aukeren zerrenda (ES):</label>
                                <div className="input-with-icon">
                                    <input type="text" ref={newActionRefES} />
                                    <span className={'create-item-span'} onClick={handleAddActionES}>
                                        <BsPlusCircleFill />
                                    </span>
                                </div>
                            </div>
                            <div className="item-list-container">
                                <ul className="item-list">
                                    {adventureActivityData.choices_es.map((item, index) => (
                                        <li key={index} className="item-with-icon">
                                            <span className={'action-span-limited'}>{item}</span>
                                            <span className={'delete-item-span'} onClick={() => {
                                                handleRemoveActionES(index);
                                            }}>
                                                <BsX />
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <button className="create-button" type="submit" >Gorde</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
