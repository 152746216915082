import React, { createContext, useState, useEffect } from 'react';
import api from '../api/api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Estado de carga

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/api/users/user/');
                setUser(response.data);

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
            } finally {
                setLoading(false); // Finalizar la carga
            }
        };

        const token = localStorage.getItem('access_token');

        if (token && !user) {
            fetchUser();
        } else {
            setLoading(false); // Finalizar la carga si no hay token o el usuario ya está cargado
        }

    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser, loading }}>
            {children}
        </UserContext.Provider>
    );
};
