import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import config from '../../config';
import './Login.css';

export function ResetPassword() {
    // Initialize const to user for navigation.
    const navigate = useNavigate();

    // Initialize password and password repeat.
    const [password, setPassword] = useState('');
    const [passwordR, setPasswordR] = useState('');

    // Initialize state for showing error message.
    const [showError, setShowError] = useState(false);
    const [showDiffPasswords, setshowDiffPasswords] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Obtener parámetros.
    const { uidb64, token } = useParams();

    const handleGoLogin = async e => {
        navigate('/login');
    }

    // Function to login.
    const handleResetPassword = async e => {
        setShowError(false);
        setshowDiffPasswords(false);
        setIsSubmitting(true);
        // Prevents event default actions.
        e.preventDefault();

        if ( password != passwordR ) {
            setshowDiffPasswords(true);
            setIsSubmitting(false);
            return;
        }

        // Set up the data to send to the API.
        const data = {
            uuid: uidb64,
            token: token,
            password: password
        };
        
        axios.post(`${config.apiHost}/api/users/reset_password/`, data , {headers: {
            'Content-Type': 'application/json'
        }}, {withCredentials: true})
        .then((response) => {
            navigate('/login');

        })
        .catch((error) => {
            setShowError(true);
            setIsSubmitting(false);
        });
    }

    return(
        <div className="auth-form-container">
          <form className="auth-form" onSubmit={handleResetPassword}>
              <div className="auth-form-content">
                  <div className="auth-form-img">
                      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="MyWay Logo" className="auth-logo-f"/>
                  </div>
                  <h2 className="info-f">Pasahitza berreskuratu</h2>
                  <div className="auth-form-password">
                      <input
                        className="password-input"
                        name='password'
                        type="password"
                        placeholder="Pasahitza"
                        value={password}
                        required
                        onChange={e => setPassword(e.target.value)}
                      />
                  </div>
                  <div className="auth-form-password">
                      <input
                        className="password-input"
                        name='password'
                        type="password"
                        placeholder="Errepikatu pasahitza"
                        value={passwordR}
                        required
                        onChange={e => setPasswordR(e.target.value)}
                      />
                  </div>
                  <div className="auth-form-button">
                      <button type="submit" className="auth-button" disabled={isSubmitting}>
                          Berreskuratu
                      </button>
                  </div>
                  <div className="auth-form-forgot">
                      <span onClick={handleGoLogin}>Saioa hasi</span>
                  </div>
                  {showDiffPasswords && <div className="auth-error"><span>Pasahitzak desberdinak dira.</span></div>}
                  {showError && <div className="auth-error"><span>Ezin izan da pasahitza berreskuratu.</span></div>}
              </div>
          </form>
        </div>
    )
}
