import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import config from "../../../config";
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import ImageTool from '@editorjs/image';
import Hyperlink from 'editorjs-hyperlink';
import ErrorPopup from "../../Alerts/ErrorPopup";
import SuccessPopup from "../../Alerts/SuccessPopup";

export function EditSummerExperiences() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const editorRef = useRef(null);
    const editorHolderRef = useRef(null); // Referencia para el contenedor de EditorJS
    const [selectedLanguage, setSelectedLanguage] = useState('eu');

    // Inicializar como objeto vacío para manejar dinámicamente múltiples idiomas
    const [summerExperiencesContent, setSummerExperiencesContent] = useState({});

    // Fetch content for all languages al montar el componente
    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await api.get(`/api/forms/summer_experiences/`);
                const data = response.data;

                // Asegurarse de que cada idioma tenga contenido, incluso si está vacío
                const initializedContent = {};
                Object.keys(data).forEach(lang => {
                    initializedContent[lang] = data[lang] || {};
                });

                setSummerExperiencesContent(initializedContent);

                // Si hay al menos un idioma, establecer el primer idioma como seleccionado si no está definido
                if (Object.keys(initializedContent).length > 0) {
                    setSelectedLanguage(prev => prev in initializedContent ? prev : Object.keys(initializedContent)[0]);
                }
            } catch (err) {
                console.error(err);
                setError("Ezin izan da notiziarik kargatu.");
            }
        };

        fetchContent();
    }, []);

    // Inicializar EditorJS cada vez que cambia el idioma seleccionado
    useEffect(() => {
        const initializeEditor = async () => {
            // Destruir el editor existente si existe y es una instancia de EditorJS
            if (editorRef.current && typeof editorRef.current.destroy === 'function') {
                try {
                    editorRef.current.destroy(); // Llamada sin encadenar .then() y .catch()
                    editorRef.current = null;
                } catch (err) {
                    console.error("Error al destruir EditorJS:", err);
                }
            }

            // Asegurarse de que el contenedor del editor existe
            if (!editorHolderRef.current) {
                console.error("El contenedor del editor no está disponible.");
                setError("Ezin izan da editoraren kontainerra aurkitu.");
                return;
            }

            try {
                // Inicializar una nueva instancia de EditorJS
                const editor = new EditorJS({
                    holder: editorHolderRef.current, // Usar la referencia en lugar de un ID
                    tools: {
                        header: Header,
                        paragraph: {
                            class: Paragraph,
                            inlineToolbar: ['hyperlink', 'bold', 'italic'],
                            config: {
                                placeholder: 'Edukia editatu...',
                            },
                        },
                        image: {
                            class: ImageTool,
                            config: {
                                endpoints: {
                                    byFile: `${config.apiHost}/api/forms/upload_summer_experiences_image/`
                                },
                                additionalRequestHeaders: {
                                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                                }
                            }
                        },
                        hyperlink: {
                            class: Hyperlink,
                            config: {
                                shortcut: 'CMD+L',
                                target: '_blank', // Hace que los enlaces se abran en una nueva pestaña
                                rel: 'noopener noreferrer', // Buena práctica de seguridad
                                availableTargets: ['_self', '_blank'],
                                availableRels: ['author', 'noopener', 'noreferrer'],
                                validate: false,
                            }
                        },
                    },
                    data: summerExperiencesContent[selectedLanguage] || {},
                    autofocus: false,
                    readOnly: false,
                    onReady: () => {
                        editorRef.current = editor;
                    },
                    onChange: async () => {
                        // Puedes manejar cambios en tiempo real si lo deseas
                    }
                });

            } catch (err) {
                console.error("Error al inicializar EditorJS:", err);
                setError("Ezin izan da EditorJS inicializatu.");
            }
        };

        // Solo inicializar si hay contenido para el idioma seleccionado
        if (summerExperiencesContent[selectedLanguage] !== undefined) {
            initializeEditor();
        }

        // Limpieza al desmontar o al cambiar dependencias
        return () => {
            if (editorRef.current && typeof editorRef.current.destroy === 'function') {
                try {
                    editorRef.current.destroy(); // Llamada sin encadenar .then() y .catch()
                    editorRef.current = null;
                } catch (err) {
                    console.error("Error al destruir EditorJS durante la limpieza:", err);
                }
            }
        };
    }, [selectedLanguage, summerExperiencesContent]);

    // Manejar el cambio de idioma
    const handleLanguageChange = async (e) => {
        const newLanguage = e.target.value;

        // Guardar el contenido actual antes de cambiar el idioma
        if (editorRef.current) {
            try {
                const content = await editorRef.current.save();
                setSummerExperiencesContent(prevState => ({
                    ...prevState,
                    [selectedLanguage]: content
                }));
            } catch (err) {
                console.error('Error al guardar el contenido del editor:', err);
                setError("Ezin izan da edukia gordetu.");
                return;
            }
        }

        // Cambiar al nuevo idioma seleccionado
        setSelectedLanguage(newLanguage);
    };

    // Manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        let updatedContent = { ...summerExperiencesContent };

        // Guardar el contenido actual antes de enviar
        if (editorRef.current) {
            try {
                const content = await editorRef.current.save();
                updatedContent[selectedLanguage] = content;
            } catch (err) {
                console.error('Error al guardar el contenido del editor:', err);
                setError("Ezin izan da edukia gordetu.");
                return;
            }
        }

        // Preparar los datos a enviar
        const dataToSend = updatedContent;

        try {
            await api.post("/api/forms/summer_experiences/", dataToSend, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            setSuccess("Aldaketak zuzen gorde dira.");
        } catch (err) {
            console.error(err);
            setError('Ezin izan dira notiziak eguneratu.');
        }
    };

    // Manejar la navegación hacia atrás
    const handleBack = () => {
        navigate("/settings");
    };

    return (
        <Fragment>
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <div className="main-content">
                <button className="back-button" onClick={handleBack}>Atzera</button>
                <div className="form-content">
                    <div className="form-container">
                        <form className="create-form" onSubmit={handleSubmit}>
                            {/* Selector de idioma */}
                            <div className="field">
                                <label className="field-name">Hizkuntza:</label>
                                <select
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                    className="language-selector"
                                >
                                    {Object.keys(summerExperiencesContent).map(lang => (
                                        <option key={lang} value={lang}>
                                            {lang.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Usar ref en lugar de id */}
                            <div ref={editorHolderRef} className="editor-js"></div>
                            <button className="create-button" type="submit">Gorde</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
