import React, { useEffect } from 'react';
import './Popup.css';

const WarningPopup = ({ message, onClose }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose();
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <div className="popup-container">
            <div className="warning-popup">
                <p>{message}</p>
            </div>
        </div>
    );
};

export default WarningPopup;