import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import SuccessPopup from "../../Alerts/SuccessPopup";
import ErrorPopup from "../../Alerts/ErrorPopup";
import "../Settings.css";

export function EditUser() {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [userData, setUserData] = useState({
        id: "",
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
    });

    useEffect(() => {
        api.get(`/api/users/get/?user_id=${userId}`)
        .then(response => {
            const { id, username, first_name, last_name, email } = response.data;
            setUserData({
                id: id,
                username: username,
                firstName: first_name,
                lastName: last_name,
                email,
                password: "",
                repeatPassword: ""
            });
        })
        .catch(error => {
            setError("Ezin izan da erabiltzailearen informazioa kargatu.");
        });
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const validateGeneralForm = () => {
        const emailRegex = /^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$/i;

        const fieldNames = {
            email: 'Emaila',
        }

        const requiredFields = [
            "email",
        ];

        for (const field of requiredFields) {
            if (!userData[field]) {
                const fieldName = fieldNames[field] || field;
                setError(`${fieldName} eremua hutsik dago.`);
                return false;
            }
        }

        if (!emailRegex.test(userData.email)) {
            const fieldName = fieldNames["email"] || "email";
            setError(`${fieldName} eremuak formatu baliogabea du.`);
            return false;
        }

        return true;
    };

    const validatePasswordForm = () => {
        const fieldNames = {
            password: 'Pasahitza',
            repeatPassword: 'Errepikatu pasahitza'
        }

        const requiredFields = [
            "password",
            "repeatPassword"
        ];

        for (const field of requiredFields) {
            if (!userData[field]) {
                const fieldName = fieldNames[field] || field;
                setError(`${fieldName} eremua hutsik dago.`);
                return false;
            }
        }

        if (userData.password !== userData.repeatPassword) {
            setError('Pasahitzak desberdinak dira.');
            return false;
        }

        return true;
    };

    const handleGeneralSubmit = async (e) => {
        e.preventDefault();

        if (!validateGeneralForm()) {
            return;
        }

        const { password, repeatPassword, ...dataToSend } = userData;
        const dataToSendModified = {
            ...dataToSend,
            user_id: dataToSend.id,
            first_name: dataToSend.firstName,
            last_name: dataToSend.lastName,
        };

        api.put(`/api/users/update/`, dataToSendModified)
        .then((response) => {
            setSuccess('Aldaketak zuzen gorde dira.');
        })
        .catch((error) => {
            setError("Ezin izan da erabiltzailea eguneratu.");
        });
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        if (!validatePasswordForm()) {
            return;
        }


        const dataToSend = {
            user_id: userData.id,
            password: userData.password,
        };

        api.put(`/api/users/update_password/`, dataToSend)
        .then((response) => {
            setSuccess('Aldaketak zuzen gorde dira.');
        })
        .catch((error) => {
            setError("Ezin izan da pasahitza eguneratu.");
        });
    };

    const handleBack = () => {
        navigate("/settings");
    }

    return (
        <Fragment>
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className="main-content">
                <button className="back-button" onClick={handleBack}>Atzera</button>
                <div className="form-content">
                    <div className="form-container">
                        <form className="create-form small-form" onSubmit={handleGeneralSubmit}>
                            <span className="form-section-span">Datu orokorrak</span>
                            <div className="field">
                                <label className="field-name">Erabiltzaile izena:</label>
                                <input type="text" disabled={true} name="username" readOnly={true} value={userData.username} onChange={handleChange} />
                            </div>
                            <div className="field">
                                <label className="field-name">Izena:</label>
                                <input type="text" name="firstName" value={userData.firstName} onChange={handleChange} />
                            </div>
                            <div className="field">
                                <label className="field-name">Abizena(k):</label>
                                <input type="text" name="lastName" value={userData.lastName} onChange={handleChange} />
                            </div>
                            <div className="field">
                                <label className="field-name">Emaila:</label>
                                <input type="text" name="email" value={userData.email} onChange={handleChange} />
                            </div>
                            <button className="create-button" type="submit">Gorde</button>
                        </form>
                        <form className="create-form small-form" onSubmit={handlePasswordSubmit}>
                            <span className="form-section-span">Pasahitza</span>
                            <div className="field">
                                <label className="field-name">Pasahitza:</label>
                                <input type="password" name="password" value={userData.password} onChange={handleChange} />
                            </div>
                            <div className="field">
                                <label className="field-name">Errepikatu pasahitza:</label>
                                <input type="password" name="repeatPassword" value={userData.repeatPassword} onChange={handleChange} />
                            </div>
                            <button className="create-button" type="submit">Gorde</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
