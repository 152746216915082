import React, { Fragment, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import './Settings.css';
import { UserContext } from '../../context/UserContext';


export function Settings() {
    // Initialize const to user for navigation.
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const handleEditMyUser = () => {
        // Go to forms page.
        navigate(`/edit_user/`);
    }

    const handleCreateUser = () => {
        // Go to forms page.
        navigate("/create_user");
    }

    const handleEditUsers = () => {
        // Go to forms page.
        navigate("/users_list");
    }

    const handleNews = () => {
        // Go to forms page.
        navigate("/news");
    }

    const handleAdventureActivities = () => {
        // Go to forms page.
        navigate("/adventure_activities_list");
    }

    const handleDefaultRulesFiles = () => {
        // Go to forms page.
        navigate("/default_rules_files");
    }

    const handleDefaultInsuranceFiles = () => {
        // Go to forms page.
        navigate("/default_insurance_files");
    }

    const handleDefaultFiles = () => {
        // Go to forms page.
        navigate("/default_files");
    }

    return (
        <Fragment>
            <div className='settings-main'>
                <div className='settings-section'>
                    <span className='settings-section-title'>Nire profila</span>
                    <span onClick={handleEditMyUser} className='settings-section-option'>Editatu</span>
                </div>
                { user.is_admin &&
                <div className='settings-section'>
                    <span className='settings-section-title'>Erabiltzaileak</span>
                    <span onClick={handleCreateUser} className='settings-section-option'>Sortu</span>
                    <span onClick={handleEditUsers} className='settings-section-option'>Erabiltzaile zerrenda</span>
                </div>
                }
                { user.is_admin &&
                <div className='settings-section'>
                    <span className='settings-section-title'>Galdetegiak</span>
                    { user.is_admin &&
                    <span onClick={handleNews} className='settings-section-option'>Notiziak/Promozioak</span>
                    }
                    { user.is_admin &&
                    <span onClick={handleAdventureActivities} className='settings-section-option'>Abenturazko ekintzak</span>
                    }
                    { user.is_admin &&
                    <span onClick={handleDefaultRulesFiles} className='settings-section-option'>Arauen lehenetsitako fitxategiak</span>
                    }
                    { user.is_admin &&
                    <span onClick={handleDefaultInsuranceFiles} className='settings-section-option'>Aseguruen lehenetsitako fitxategiak</span>
                    }
                    { user.is_admin &&
                    <span onClick={handleDefaultFiles} className='settings-section-option'>Beste lehenetsitako fitxategiak</span>
                    }
                </div>
                }
            </div>
        </Fragment>
    );
}
