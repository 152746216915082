import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useState, useContext} from "react";
import api from "../../api/api";
import { UserContext } from "../../context/UserContext";
import config from '../../config';
import './Login.css';

export function Login() {
    const { setUser } = useContext(UserContext);
    // Initialize const to user for navigation.
    const navigate = useNavigate();

    // Initialize username and password.
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Initialize state for showing error message.
    const [showError, setShowError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Function to login.
    const handleForgotPassword = async e => {
        navigate('/forgot_password');
    }

    // Function to login.
    const handleLogin = async e => {
        setShowError(false);
        setIsSubmitting(true);
        // Prevents event default actions.
        e.preventDefault();

        // Set up the data to send to the API.
        const user = {
            username: username,
            password: password
        };
        
        axios.post(`${config.apiHost}/token/`, user , {headers: {
            'Content-Type': 'application/json'
        }}, {withCredentials: true})
        .then((response) => {
            // Clear local storage and set access and refresh tokens.
            localStorage.clear();
            localStorage.setItem('access_token', response.data['access']);
            localStorage.setItem('refresh_token', response.data['refresh']);

            // Fetch user data after login.
            api.get('/api/users/user/')
            .then((response) => {
                setUser(response.data);
                // Navigate to home.
                navigate('/home');
            })
            .catch((error) => {
                // Handle error by showing error message and clearing input fields.
                setShowError(true);
                setUsername('');
                setPassword('');
                document.getElementsByName("username")[0].blur();
                document.getElementsByName("password")[0].blur();
                setIsSubmitting(false);
            });

        })
        .catch((error) => {
            // Handle error by showing error message and clearing input fields.
            setShowError(true);
            setUsername('');
            setPassword('');
            document.getElementsByName("username")[0].blur();
            document.getElementsByName("password")[0].blur();
            setIsSubmitting(false);
        });

    }

    return(
        <div className="auth-form-container">
          <form className="auth-form" onSubmit={handleLogin}>
              <div className="auth-form-content">
                  <div className="auth-form-img">
                      <img src="logo.png" alt="MyWay Logo" className="auth-logo"/>
                  </div>
                  <div className="auth-form-username">
                      <input
                        className="username-input"
                        placeholder="Erabiltzailea"
                        name='username'
                        type='text'
                        value={username}
                        required
                        onChange={e => setUsername(e.target.value)}
                      />
                  </div>
                  <div className="auth-form-password">
                      <input
                        className="password-input"
                        name='password'
                        type="password"
                        placeholder="Pasahitza"
                        value={password}
                        required
                        onChange={e => setPassword(e.target.value)}
                      />
                  </div>
                  <div className="auth-form-button">
                      <button type="submit" className="auth-button" disabled={isSubmitting}>
                          Sartu
                      </button>
                  </div>
                  <div className="auth-form-forgot">
                      <span onClick={handleForgotPassword}>Pasahitza ahaztu duzu?</span>
                  </div>
                  {showError && <div className="auth-error"><span>Kredentzialak ez dira zuzenak.</span></div>}
              </div>
          </form>
        </div>
    )
}
