import axios from 'axios';
import config from '../config'

const api = axios.create({
    baseURL: `${config.apiHost}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
    
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
    
            try {
                const refresh = localStorage.getItem('refresh_token');
                const response = await axios.post(`${config.apiHost}/token/refresh/`, { refresh });
        
                localStorage.setItem('access_token', response.data['access']);
                localStorage.setItem('refresh_token', response.data['refresh']);
        
                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${response.data['access']}`;

                return axios(originalRequest);

            } catch (error) {
                localStorage.clear();
                window.location.href = '/login';
            }
        }
  
        return Promise.reject(error);
    }
);

export default api;
