import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from '../../context/UserContext';
import { BsPerson } from "react-icons/bs";
import './Header.css';

export function Header() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null); // Ref para el popup

    // Mapear rutas a nombres de páginas
    const pageNames = {
        '/home': 'Galdetegiak',
        '/create_form': 'Galdetegia sortu',
        '/edit_form/:formId': 'Galdetegia editatu',
        '/form_instances/:formId': 'Ikasle zerrenda',
        '/settings': 'Ezarpenak',
        '/create_user': 'Erabiltzailea sortu',
        '/users_list': 'Erabiltzaile zerrenda',
        '/edit_user/': 'Nire erabiltzailea',
        '/edit_user/:userId': 'Erabiltzailea editatu',
        '/news': 'Notiziak/Promozioak',
        '/default_rules_files': 'Arauen lehenetsitako fitxategiak',
        '/default_insurance_files': 'Aseguruen lehenetsitako fitxategiak',
        '/default_files': 'Beste lehenetsitako fitxategiak',
        '/adventure_activities_list': 'Abenturazko ekintzen zerrenda',
        '/create_adventure_activity': 'Abenturazko ekintza sortu',
        '/edit_adventure_activity/:adventureActivityId': 'Abenturazko ekintza editatu',
    };

    // Función para manejar rutas dinámicas
    const getPageName = (pathname) => {
        // Expresión regular para UUID (formId)
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

        // Rutas dinámicas con expresiones regulares
        if (/^\/edit_user\/\d+$/.test(pathname)) {
            return 'Erabiltzailea editatu'; // Ruta como /edit_user/123
        }
        if (pathname.startsWith('/edit_form/') && uuidRegex.test(pathname.split('/').pop())) {
            return 'Galdetegia editatu'; // Ruta como /edit_form/{uuid}
        }
        if (pathname.startsWith('/form_instances/') && uuidRegex.test(pathname.split('/').pop())) {
            return 'Ikasle zerrenda'; // Ruta como /edit_form/{uuid}
        }
        if (/^\/edit_adventure_activity\/\d+$/.test(pathname)) {
            return 'Abenturazko ekintza editatu'; // Ruta como /edit_adventure_activity/456
        }

        // Verificar si la ruta está en el mapeo de rutas estáticas
        return pageNames[pathname] || ''; // Valor predeterminado si no se encuentra la ruta
    };

    // Obtener el nombre de la página actual
    const currentPage = getPageName(location.pathname);
    
    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            localStorage.clear();
            navigate('/login');
        }
    }, [navigate]); // Agregué navigate a las dependencias para evitar bucles infinitos.

    const handleHome = () => {
        navigate("/home");
    }

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    // Cerrar el modal al hacer clic fuera de él
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        };

        if (showPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Limpiar evento
        };
    }, [showPopup]);

    return (
        <header className="header">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="MyWay Logo" className="header-logo" onClick={handleHome}/>
            <span className='page-name'>{currentPage}</span>
            <div className="header-user">
                <span>{user ? user.username : ''}</span>
                <span className='header-icon-span'>
                    <BsPerson className='header-icon' onClick={togglePopup} />
                </span>
                {showPopup && (
                    <div className="popup" ref={popupRef}>
                        <span onClick={handleLogout}>Saioa itxi</span>
                    </div>
                )}
            </div>
        </header>
    );
}
