import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import config from '../../config';
import './Login.css';

export function ForgotPassword() {
    // Initialize const to user for navigation.
    const navigate = useNavigate();

    // Initialize username and password.
    const [username, setUsername] = useState('');

    // Initialize state for showing error message.
    const [showError, setShowError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleGoLogin = async e => {
        navigate('/login');
    }

    // Function to login.
    const handleForgotPassword = async e => {
        setShowError(false);
        setIsSubmitting(true);
        // Prevents event default actions.
        e.preventDefault();

        // Set up the data to send to the API.
        const user = {
            username: username
        };
        
        axios.post(`${config.apiHost}/api/users/forgot_password/`, user , {headers: {
            'Content-Type': 'application/json'
        }}, {withCredentials: true})
        .then((response) => {
            navigate('/login');

        })
        .catch((error) => {
            setShowError(true);
            setIsSubmitting(false);
        });
    }

    return(
        <div className="auth-form-container">
          <form className="auth-form" onSubmit={handleForgotPassword}>
              <div className="auth-form-content">
                  <div className="auth-form-img">
                      <img src="logo.png" alt="MyWay Logo" className="auth-logo-f"/>
                  </div>
                  <h2 className="info-f">Erabiltzailea sartzean mezu bat iritsiko zaizu pasahitza berreskuratzeko jarritu beharreko pausuak azalduz</h2>
                  <div className="auth-form-username-f">
                      <input
                        className="username-input"
                        placeholder="Erabiltzailea"
                        name='username'
                        type='text'
                        value={username}
                        required
                        onChange={e => setUsername(e.target.value)}
                      />
                  </div>
                  <div className="auth-form-button">
                      <button type="submit" className="auth-button" disabled={isSubmitting}>
                          Bidali mezua
                      </button>
                  </div>
                  <div className="auth-form-forgot">
                      <span onClick={handleGoLogin}>Atzera</span>
                  </div>
                  {showError && <div className="auth-error"><span>Erabiltzailea ez da existitzen.</span></div>}
              </div>
          </form>
        </div>
    )
}
