import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { BsTrash3, BsPencil } from 'react-icons/bs';
import '../Settings.css';

export function AdventureActivitiesListElement({ adventureActivity, onDeleteAdventureActivity }) {
    const navigate = useNavigate();
    const [adventureActivityState, ] = useState(adventureActivity);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleEdit = () => {
        navigate(`/edit_adventure_activity/${adventureActivity.id}`);
    };

    const handleDelete = () => {
        onDeleteAdventureActivity(adventureActivity.id);
    };

    const handleDeleteConfirm = () => {
        setConfirmDelete(true);
    };

    const handleDeleteCancel = () => {
        setConfirmDelete(false);
    };

    const handleDeleteConfirmProceed = () => {
        handleDelete();
        setConfirmDelete(false);
    };

    const handleOverlayClick = (event) => {
        if (event.target.className === 'modal-overlay') {
            setConfirmDelete(false);
        }
    };

    return (
        <tr>
            <td>{adventureActivityState.title_eu}</td>
            <td>{adventureActivityState.title_es}</td>
            <td className='td-right'>
                <div className='clickable-icons'>
                    <span className='clickable-span' onClick={() => handleEdit(adventureActivityState.id)}>
                        <BsPencil />
                    </span>
                    {' '}
                    <span className='clickable-span' onClick={handleDeleteConfirm}>
                        <BsTrash3 />
                    </span>
                </div>
                {confirmDelete && (
                    <div className="modal-overlay" onClick={handleOverlayClick}>
                        <div className="modal-content">
                            <p>Ziur zaude abenturazko ekintza ezabatu nahi duzula?</p>
                            <button className='delete-button' onClick={handleDeleteConfirmProceed}>Ezabatu</button>
                            <button className='back-button' onClick={handleDeleteCancel}>Atzera</button>
                        </div>
                    </div>
                )}
            </td>
        </tr>
    );
}
