import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { FormInstancesElementPreview } from './FormInstancesElementPreview';
import ErrorPopup from '../Alerts/ErrorPopup';
import './Forms.css';

export function FormInstancesPreview() {
    const { formId } = useParams();
    const [error, setError] = useState(null);
    const [form, setForm] = useState(null);
    const [formInstances, setFormInstances] = useState([]);
    const [datetimeFromAPI, setDatetimeFromAPI] = useState(null); // New state variable for datetime

    // Get the host from the .env file
    const apiHost = process.env.REACT_APP_API_HOST;

    // Fetch form data
    const fetchForm = useCallback(async () => {
        try {
            const response = await fetch(`${apiHost}/api/forms/form/?form_id=${formId}`);
            if (!response.ok) {
                throw new Error('Ezin izan da galdetegia eskuratu.');
            }
            const data = await response.json();
            setForm(data);
        } catch (error) {
            setError('Ezin izan da galdetegia eskuratu.');
        }
    }, [apiHost, formId]);

    // Fetch form instances and datetime from API response
    const fetchFormInstances = useCallback(async () => {
        try {
            const url = `${apiHost}/api/forms/form_instances_list_preview/?form_id=${formId}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Ezin izan dira galdetegiaren instantziak eskuratu.');
            }
            const data = await response.json();
            setFormInstances(data.instances);
            setDatetimeFromAPI(data.datetime); // Set datetime from API response
        } catch (error) {
            setError('Ezin izan dira galdetegiaren instantziak eskuratu.');
        }
    }, [apiHost, formId]);

    // Use effect to fetch data on component mount
    useEffect(() => {
        fetchForm();
        fetchFormInstances();
    }, [fetchForm, fetchFormInstances]);

    return (
        <Fragment>
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <div className='main-content preview'>
                {form ? <h2>{form.name}</h2> : null}
                {/* Display datetime if it exists */}
                {datetimeFromAPI ? (
                    <h4>{datetimeFromAPI.replace("T", " ")}</h4>
                ) : null}
                <div className='table-main'>
                    <div className="table-elements preview">
                        <Table>
                            <thead className="forms-header">
                                <tr>
                                    <th>Izena</th>
                                    <th>Abizena 1</th>
                                    <th>Abizena 2</th>
                                    <th>Generoa</th>
                                    <th>Jaiotze data</th>
                                    <th>Helbidea</th>
                                    <th>Herria</th>
                                    <th>Posta kodea</th>
                                    <th>Nazionalitatea</th>
                                    <th>Dokumentu mota</th>
                                    <th>Dokumentu zbkia</th>
                                    <th>Bidaltze data</th>
                                    <th>Iraungitze data</th>
                                    <th>Tlf. Zbkia</th>
                                    <th>1. Tutorearen izena</th>
                                    <th>1. Tutorearen dokumentu mota</th>
                                    <th>1. Tutorearen dokumentu zbkia</th>
                                    <th>1. Tutorearen emaila</th>
                                    <th>1. Tutorearen tlf. zbkia</th>
                                    <th>2. Tutorearen izena</th>
                                    <th>2. Tutorearen dokumentu mota</th>
                                    <th>2. Tutorearen dokumentu zbkia</th>
                                    <th>2. Tutorearen emaila</th>
                                    <th>2. Tutorearen tlf. zbkia</th>
                                    <th>Janari alergiak</th>
                                    <th>Bestelako alergiak</th>
                                    <th>Gaixotasunak</th>
                                    <th>Komunikatu?</th>
                                    <th>Klasea</th>
                                    <th>Kamiseta neurria</th>
                                    {
                                        form && form.choice_of_actions ? (
                                            form.choice_of_actions_json.map((choice_of_actions) => (
                                                <th key={choice_of_actions.day}>Ekintzak: {choice_of_actions.question_eu}</th>
                                            ))
                                        ) : null
                                    }
                                    <th>Abenturazko ekintzak</th>
                                    <th>Arduradunaren komunikabideetan argitaratu?</th>
                                    <th>Arduradunaz kanpoko komunikabideetan argitaratu?</th>
                                    <th>Balioztatuta?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th 
                                        colSpan={34 + (form && form.choice_of_actions_json ? form.choice_of_actions_json.length : 0)} 
                                        style={{ width: '100%' }}
                                    >
                                        <hr />
                                    </th>
                                </tr>

                                {formInstances.length === 0 ? (
                                    <tr>
                                        <td className='no-forms' colSpan={33}>
                                            <div>Ez dago galdetegiaren instantziarik</div>
                                        </td>
                                    </tr>
                                ) : (
                                    formInstances.map((formInstance) => (
                                        <FormInstancesElementPreview
                                            key={formInstance.uuid}
                                            formInstance={formInstance}
                                        />
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
