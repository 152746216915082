import React, { useState } from 'react';
import { BsTrash3, BsPencil } from 'react-icons/bs';
import MessagePopup from '../Alerts/MessagePopup';
import ErrorPopup from '../Alerts/ErrorPopup';
import './Forms.css';

export function FormInstancesElement({ formInstance, onDeleteFormInstance }) {
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const isNew = formInstance.changed_fields && formInstance.changed_fields.includes('new');

    const fieldChanged = (fieldName) => {
        return formInstance.changed_fields && formInstance.changed_fields.includes(fieldName);
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await onDeleteFormInstance(formInstance.uuid);
            setMessage("Instantzia zuzen ezabatu da.");
        } catch (err) {
            setError('Ezin izan da instantzia ezabatu.');
        } finally {
            setIsDeleting(false);
            setConfirmDelete(false);
        }
    };

    const handleDeleteConfirm = () => {
        setConfirmDelete(true);
    };

    const handleDeleteCancel = () => {
        setConfirmDelete(false);
    };

    const handleOverlayClick = (event) => {
        if (event.target.className === 'modal-overlay') {
            setConfirmDelete(false);
        }
    };

    const handleEdit = () => {
        if (formInstance.wp_page) {
            window.open(formInstance.wp_page, '_blank');
        } else {
            setError('URL no disponible para esta instancia.');
        }
    };

    return (
        <>
            <MessagePopup message={message} onClose={() => setMessage(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            {confirmDelete && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content">
                        <p>Ziur zaude instantzia ezabatu nahi duzula?</p>
                        <button className='delete-button' onClick={handleDelete} disabled={isDeleting}>
                            {isDeleting ? 'Ezabatzen...' : 'Ezabatu'}
                        </button>
                        <button className='back-button' onClick={handleDeleteCancel}>Atzera</button>
                    </div>
                </div>
            )}
            <tr>
                <td>
                    <div className='instance-icons'>
                        <span 
                            className='clickable-span' 
                            onClick={handleEdit}
                            title="Editar"
                        >
                            <BsPencil />
                        </span>
                        <span 
                            className='clickable-span' 
                            onClick={handleDeleteConfirm}
                            title="Eliminar"
                        >
                            <BsTrash3 />
                        </span>
                    </div>
                </td>
                <td className={isNew ? 'new-instance' : fieldChanged('name') ? 'changed-field' : ''}>{formInstance.name}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('surname_1') ? 'changed-field' : ''}>{formInstance.surname_1}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('surname_2') ? 'changed-field' : ''}>{formInstance.surname_2}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('gender') ? 'changed-field' : ''}>{formInstance.gender}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('birthdate') ? 'changed-field' : ''}>{formInstance.birthdate}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('address') ? 'changed-field' : ''}>{formInstance.address}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('location') ? 'changed-field' : ''}>{formInstance.location}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('zip_code') ? 'changed-field' : ''}>{formInstance.zip_code}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('nationality') ? 'changed-field' : ''}>{formInstance.nationality}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('document_type') ? 'changed-field' : ''}>{formInstance.document_type}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('document_number') ? 'changed-field' : ''}>{formInstance.document_number}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('expedition_date') ? 'changed-field' : ''}>{formInstance.expedition_date}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('expiration_date') ? 'changed-field' : ''}>{formInstance.expiration_date}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('phone') ? 'changed-field' : ''}>{formInstance.phone}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_fullname') ? 'changed-field' : ''}>{formInstance.tutor_fullname}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_document_type') ? 'changed-field' : ''}>{formInstance.tutor_document_type}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_document_number') ? 'changed-field' : ''}>{formInstance.tutor_document_number}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_email') ? 'changed-field' : ''}>{formInstance.tutor_email}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_phone') ? 'changed-field' : ''}>{formInstance.tutor_phone}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_fullname_2') ? 'changed-field' : ''}>{formInstance.tutor_fullname_2}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_document_type_2') ? 'changed-field' : ''}>{formInstance.tutor_document_type_2}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_document_number_2') ? 'changed-field' : ''}>{formInstance.tutor_document_number_2}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_email_2') ? 'changed-field' : ''}>{formInstance.tutor_email_2}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('tutor_phone_2') ? 'changed-field' : ''}>{formInstance.tutor_phone_2}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('food_allergies') ? 'changed-field' : ''}>{formInstance.food_allergies}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('other_allergies') ? 'changed-field' : ''}>{formInstance.other_allergies}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('diseases') ? 'changed-field' : ''}>{formInstance.diseases}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('communicate') ? 'changed-field' : ''}>{formInstance.communicate}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('classroom') ? 'changed-field' : ''}>{formInstance.classroom}</td>
                <td className={isNew ? 'new-instance' : fieldChanged('shirt_size') ? 'changed-field' : ''}>{formInstance.shirt_size}</td>
                {/* Handle selected_actions */}
                {formInstance && formInstance.selected_actions ? (
                    Object.entries(formInstance.selected_actions).map(([key, actions], index) => (
                        <td 
                            key={index} 
                            className={isNew ? 'new-instance' : fieldChanged('selected_actions') ? 'changed-field' : ''}
                        >
                            {actions.join(", ")}
                        </td>
                    ))
                ) : null}
                {/* Handle adventure_activity_choice */}
                {formInstance && formInstance.adventure_activity_choice ? (
                    <td className={isNew ? 'new-instance' : fieldChanged('adventure_activity_choice') ? 'changed-field' : ''}>
                        {formInstance.adventure_activity_choice}
                    </td>
                ) : null}
                <td className={isNew ? 'new-instance' : fieldChanged('record_images_responsable_checked') ? 'changed-field' : ''}>
                    {formInstance.record_images_responsable_checked}
                </td>
                <td className={isNew ? 'new-instance' : fieldChanged('record_images_social_media_checked') ? 'changed-field' : ''}>
                    {formInstance.record_images_social_media_checked}
                </td>
                <td className={isNew ? 'new-instance' : fieldChanged('verified') ? 'changed-field' : ''}>{formInstance.verified}</td>
            </tr>
        </>
    );
}
