import React from 'react';
import { useNavigate } from "react-router-dom";
import { BsFileEarmarkText, BsGear } from "react-icons/bs";
import './Sidebar.css';

export function Sidebar() {
  // Initialize const to user for navigation.
  const navigate = useNavigate();

  const handleHome = () => {
      // Go to forms page.
      navigate("/home");
  }

  const handleSettings = () => {
      // Go to settings page.
      navigate("/settings");
  }
  
  return (
    <aside className="sidebar">
        <div className='sidebar-menu'>
            <div className='sidebar-element'>
                <BsFileEarmarkText className='sidebar-icon' />
                <span onClick={handleHome}>Galdetegiak</span>
            </div>
            <div className='sidebar-settings'>
                <BsGear className='sidebar-icon' />
                <span onClick={handleSettings}>Ezarpenak</span>
            </div>
        </div>
    </aside>
  );
}
